import React, { FC, ReactElement } from 'react'

import { graphql, Link, useStaticQuery } from 'gatsby';
import * as styles from './Blog.module.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '~components/Layout';
import StickyBox from "react-sticky-box";
import SocialShare from '~components/SocialShare';
import Moment from 'react-moment';

const BlogTemplate: FC<unknown> = (params): ReactElement => {
  const { article: articles, allContentfulBlogPage } = useStaticQuery(query);
  //TODO: filters not working
  const article = articles.edges.find(edge => edge.node.id === params.pageContext.id).node;
  const image = getImage(article.image)
  const instagramLink = allContentfulBlogPage.nodes[0].instagramLink;

  const isBrowser = typeof window !== "undefined"
  const locationHref = isBrowser && window.location.href;

  const imageBlock = (
    image && (
      <GatsbyImage
        alt={``}
        image={image}
        className={styles.image}
      />
    )
  );

  const desktopTitleBlock = (
    <div className={`${styles.title} ${styles.desktop}`}>
      <h1>{article.title}</h1>
      <div className={styles.category}>
        {article.category[0].title}
        •
        <Moment format="DD MMM YYYY">
          {article.published}
        </Moment>
      </div>
    </div>
  );

  const mobileTitleBlock = (
    <div className={`${styles.title} ${styles.mobile}`}>
      <h1>{article.title}</h1>
    </div>
  )

  const desktopShareBlock = (
    <StickyBox offsetTop={120} offsetBottom={120}>
      <div className={`${styles.share} ${styles.desktop}`}>
        <SocialShare title={article.title} link={locationHref} instagramLink={instagramLink} />
      </div>
    </StickyBox>
  )

  const mobileShareBlock = (
    <div className={`${styles.share} ${styles.mobile}`}>
      <SocialShare title={article.title} link={locationHref} instagramLink={instagramLink} />
    </div>
  )

  const mobileCategory = (
    <div className={`${styles.mobile} ${styles.category}`}>
      <div className={styles.categoryCol}>
        <div className={styles.categoryTitle}>Category</div>
        <div>{article.category[0].title}</div>
      </div>
      <div className={styles.categoryCol}>
        <div className={styles.categoryTitle}>Published</div>
        <div>
          <Moment format="DD MMM YYYY">
            {article.published}
          </Moment>
        </div>
      </div>
    </div>
  )

  return (
    <Layout>
      <div className={styles.root}>
        <div className={`${styles.bread} ${styles.desktop}`}>
          <Link to='/blog'>Blog</Link>
          <span>></span>
          <Link to={`/blog/${article.slug}`}>{article.title}</Link>
        </div>
        {mobileTitleBlock}
        {imageBlock}
        {mobileCategory}
        {mobileShareBlock}
        <div className={styles.scrollable}>
          {desktopShareBlock}
          <div className={styles.content}>
            {desktopTitleBlock}
            <div className={styles.text}>
              {documentToReactComponents(JSON.parse(article.content.raw))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogTemplate


export const query = graphql`
query BlogPageQuery($id: String) {
  allContentfulBlogPage {
    nodes {
      instagramLink
    }
  }
  article: allContentfulBlogPost(filter: {id: {eq: $id}}) {
    edges {
      node {
        id
        slug
        title
        published
        description {
          description
        }
        category {
          title
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 98
            placeholder: TRACED_SVG
          )
        }
        content {
          raw
        }
      }
    }
  }
}
`
