// extracted by mini-css-extract-plugin
export var root = "Blog-module--root--3W_Gu";
export var bread = "Blog-module--bread--1o3S-";
export var scrollable = "Blog-module--scrollable--2OFXV";
export var desktop = "Blog-module--desktop--h7tLL";
export var mobile = "Blog-module--mobile--oqoVv";
export var image = "Blog-module--image--1_aOF";
export var title = "Blog-module--title--A7PUk";
export var category = "Blog-module--category--3WseI";
export var categoryCol = "Blog-module--categoryCol--hV9fs";
export var categoryTitle = "Blog-module--categoryTitle--3jXZg";
export var content = "Blog-module--content--26fx3";
export var text = "Blog-module--text--1iaNi";